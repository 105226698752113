.footer {
  background: rgb(88, 134, 78);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  padding: 8px;
  text-align: center;

  .links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 110px;
    width: 45%;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-evenly;
    }

    a {
      display: flex;
    }

    .instagram {
      position: absolute;
      cursor: pointer;
      right: 150px;

      @media screen and (max-width: 768px) {
        position: relative;
        right: 0;
      }
    }
  }
}