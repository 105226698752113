@import url('https://fonts.cdnfonts.com/css/josefin-sans');

.pageContainer {
  display: flex;
  justify-content: center;
  background: #5a5a3e;
}

.hr {
  border-bottom: 1px solid black;
  min-width: 100%;
}