/* Navbar.css */

.header {
  width: 100%;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background: rgb(88, 134, 78);
  justify-content: center;



  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100px;
  }
}

.nav__logo {
  img {
    height: 150px;
  }
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
}

.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

@media screen and (max-width: 1150px) {
  .nav__menu {
    position: fixed;
    top: 0;
    display: none;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    height: 100%;
    padding: 6rem 3rem 0;
    transition: left 0.4s;
    justify-content: center;
    width: 50%;
    background-color: rgba(240, 233, 164, 0.5);
  }
}
@media screen and (max-width: 300px) {
  .nav__menu {
    padding: 4rem 1rem 2rem;
  }

  .nav__list {
    row-gap: 1rem;
  }

  .nav__link,
  .nav__cta {
    font-size: var(--smaller-font-size);
  }

  .nav__cta {
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 1rem;
    width: auto;
    white-space: nowrap;
  }
}

.nav__list {

}

.nav__link {
  transition: color 0.4s;
  display: flex;
  align-items: center;
  height: 50px;
  padding-top: 50px;

  a {
    display: flex;
  }
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
  display: flex;
  padding-top: 20px;
  align-items: baseline;

  a {
    margin-bottom: 8px;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.nav__menu_mobile {
  position: fixed;
  top: 0;
  right: -100%;
  background-color: hsla(0, 0%, 6%, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  width: 80%;
  height: 100%;
  padding: 6rem 3rem 0;
  transition: left 0.4s;
}
@media screen and (min-width: 1150px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
  }
  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
    align-items: center;
  }
}